export const makersData = [
  {
    team: "기획・디자인",
    name: "곽은진",
    dept: "커뮤니케이션미디어학부 18",
    task: "기획 디자인 팀 리드  \n2차 디자인 전반 수정 및 제작",
  },
  {
    team: "기획・디자인",
    name: "김다은",
    dept: "국어국문학과 21",
    task: "메인, 메뉴바, 로그인/회원가입/검색, tf 게시판, 쓰레기통 지도 페이지, 와이어프레임 제작,\n메인 페이지 디자인",
  },
  {
    team: "기획・디자인",
    name: "박성연",
    dept: "생명과학과 18",
    task: "로그인 페이지, 쓰레기통 위치 페이지, 댓글창,\n공지사항 페이지 디자인",
  },
  {
    team: "기획・디자인",
    name: "신다윤",
    dept: "컴퓨터공학전공 18",
    task: "부스 메인 페이지, 부스 상세 페이지, 마이페이지,\n정보 수정 페이지 와이어프레임 제작",
  },
  {
    team: "백엔드 개발",
    name: "김도연",
    dept: "컴퓨터공학전공 20",
    task: "백엔드 개발 팀 리드\n서버 구축, 부스 및 유저 관련 기능 전반 구현, 이미지 데이터 처리",
  },
  {
    team: "백엔드 개발",
    name: "신이수",
    dept: "중어중문학과 18",
    task: "TF공지 목록 조회, 데이터 정리 및 쿼리문 작성",
  },
  {
    team: "백엔드 개발",
    name: "조현영",
    dept: "컴퓨터공학전공 20",
    task: "댓글 작성, 데이터 정리 및 쿼리문 작성",
  },
  {
    team: "백엔드 개발",
    name: "임채영",
    dept: "컴퓨터공학전공 21",
    task: "TF공지 수정, 데이터 정리 및 쿼리문 작성",
  },
  {
    team: "백엔드 개발",
    name: "최유미",
    dept: "컴퓨터공학전공 21",
    task: "TF공지 작성, 데이터 정리 및 쿼리문 작성",
  },
  {
    team: "백엔드 개발",
    name: "김정은",
    dept: "화학나노과학과 21",
    task: "API 문서 작성, 데이터 정리 및 쿼리문 작성",
  },
  {
    team: "백엔드 개발",
    name: "이나경",
    dept: "휴먼기계바이오공학과 22",
    task: "TF공지 상세 조회, 데이터 정리 및 쿼리문 작성",
  },
  {
    team: "프론트엔드 개발",
    name: "정다윤",
    dept: "컴퓨터공학전공 20",
    task: "프론트엔드 개발 팀 리드\n메인 페이지, 카테고리, 검색페이지\n유저 인증 관리 구현, 성능 최적화",
  },
  {
    team: "프론트엔드 개발",
    name: "김혜빈",
    dept: "사회복지학과 19",
    task: "TF 공지 작성 페이지, TF 공지 수정 페이지,\n모달 컴포넌트 구현",
  },
  {
    team: "프론트엔드 개발",
    name: "김민주",
    dept: "사이버보안전공 19",
    task: "마이페이지(일반 회원, 관리자), 쓰레기 통 페이지",
  },
  {
    team: "프론트엔드 개발",
    name: "정연주",
    dept: "사이버보안전공 20",
    task: "부스 수정 페이지, 메뉴 수정 페이지",
  },
  {
    team: "프론트엔드 개발",
    name: "허윤",
    dept: "커뮤니케이션미디어학부 21",
    task: "TF 공지 메인 페이지, TF 공지 상세 페이지",
  },
  {
    team: "프론트엔드 개발",
    name: "이서진",
    dept: "컴퓨터공학전공 21",
    task: "부스 상세 페이지",
  },
  {
    team: "프론트엔드 개발",
    name: "이채원",
    dept: "뇌·인지과학부 21",
    task: "로그인, 회원가입, 만든이들 페이지, 사이드 바",
  },
  {
    team: "기획",
    name: "이윤정",
    dept: "국제사무학과 19",
    task: "대동제의 다양한 프로그램 기획 및 총괄",
  },
  {
    team: "기획",
    name: "김예인",
    dept: "영어영문학부 20",
  },
  {
    team: "기획",
    name: "김지원",
    dept: "통계학과 22",
  },
  {
    team: "기획",
    name: "이수영",
    dept: "휴먼기계바이오공학부 20",
  },
  {
    team: "기획",
    name: "한윤희",
    dept: "경영학부 21",
  },
  {
    team: "기획",
    name: "황현주",
    dept: "과학교육과 21",
  },
  {
    team: "사무",
    name: "한지수",
    dept: "영어영문학부 20",
    task: "예결산 관리, 프로모션을 위한 업체 컨택",
  },
  {
    team: "사무",
    name: "강도연",
    dept: "중어중문학과 22",
  },
  {
    team: "사무",
    name: "김수아",
    dept: "관현악과 20",
  },
  {
    team: "사무",
    name: "전하영",
    dept: "생명과학과 20",
  },
  {
    team: "사무",
    name: "정은주",
    dept: "호크마 22",
  },
  {
    team: "무대",
    name: "김민지",
    dept: "관현악과 20",
    task: "동아리 무대, 특별 무대 등 구성, 현장 관리",
  },
  {
    team: "무대",
    name: "박영진",
    dept: "국제학과 22",
  },
  {
    team: "무대",
    name: "박채원",
    dept: "휴먼기계바이오공학부 22",
  },
  {
    team: "무대",
    name: "이세영",
    dept: "커뮤니케이션미디어학부 20",
  },
  {
    team: "무대",
    name: "이재윤",
    dept: "디자인학부 22",
  },
  {
    team: "무대",
    name: "조민서",
    dept: "간호학부 20",
  },
  {
    team: "홍보",
    name: "김다정",
    dept: "수학교육과 19",
    task: "현수막, 포스터, 카드뉴스, 책자, 영상 등 다양한 홍보물 기획 및 제작",
  },
  {
    team: "홍보",
    name: "권서윤",
    dept: "간호학부 22",
  },
  {
    team: "홍보",
    name: "박해원",
    dept: "정치외교학과 21",
  },
  {
    team: "홍보",
    name: "송시연",
    dept: "커뮤니케이션미디어학부 20",
  },
  {
    team: "홍보",
    name: "임연서",
    dept: "불어불문학과 22",
  },
  {
    team: "연대",
    name: "채민경",
    dept: "기후에너지시스템공학과 20",
    task: "연대 단위와의 소통, 연대 단위 부스 관리",
  },
  {
    team: "연대",
    name: "김소희",
    dept: "독어독문학과 21",
  },
  {
    team: "연대",
    name: "유진",
    dept: "간호학부 20",
  },
  {
    team: "연대",
    name: "이유하",
    dept: "건축도시시스템공학과 20",
  },
  {
    team: "부스",
    name: "박세연",
    dept: "식품영양학과 20",
    task: "부스 대여 및 각 단위의 부스 신청 관련 총괄",
  },
  {
    team: "부스",
    name: "김세민",
    dept: "소비자학과 21",
  },
  {
    team: "부스",
    name: "백희수",
    dept: "국제학부 20",
  },
  {
    team: "부스",
    name: "유인경",
    dept: "소비자학과 22",
  },
  {
    team: "부스",
    name: "이민채",
    dept: "융합보건학과 21",
  },
  {
    team: "부스",
    name: "이상희",
    dept: "중어중문학과 18",
  },
  {
    team: "부스",
    name: "이유림",
    dept: "특수교육학과 21",
  },
  {
    team: "부스",
    name: "이은서",
    dept: "경영학부 22",
  },
  {
    team: "부스",
    name: "이준이",
    dept: "사회교육과 21",
  },
  {
    team: "부스",
    name: "이채은",
    dept: "휴먼기계바이오공학부 22",
  },
  {
    team: "부스",
    name: "지수민",
    dept: "건축학과 19",
  },
  {
    team: "꼬우미",
    name: "이세라",
    dept: "사회학과 20",
    task: "이화인 한솥밥, 영산줄다리기 행사 구성 및 진행\n(136주년 대동제에서는 대체 프로그램으로 기획)",
  },
  {
    team: "꼬우미",
    name: "김인우",
    dept: "생명과학과 19",
  },
  {
    team: "꼬우미",
    name: "송가윤",
    dept: "영어교육학과 21",
  },
  {
    team: "꼬우미",
    name: "김수지",
    dept: "영어영문학부 20",
  },
  {
    team: "꼬우미",
    name: "박하은",
    dept: "휴먼기계바이오공학부 21",
  },
];
