export const trashData = [
  {
    id: 1,
    name: "포스코관",
    info: "포스코관 건물 옆",
    top: "115",
    left: "160",
    selected: false,
  },
  {
    id: 2,
    name: "학생문화관",
    info: "학생문화관 7~14번 부스 건너편 공간",
    top: "130",
    left: "135",
    selected: false,
  },
  {
    id: 3,
    name: "생활환경관(1)",
    info: "생활환경관 입구",
    top: "158",
    left: "117",
    selected: false,
  },
  {
    id: 4,
    name: "생활환경관(2)",
    info: "생활환경관 20번 부스 옆",
    top: "177",
    left: "94",
    selected: false,
  },
  {
    id: 5,
    name: "정문",
    info: "정문 24번 부스 옆",
    top: "192",
    left: "202",
    selected: true,
  },
];
