export const locationData = [
  { id: 1, name: "정문", selected: false },
  { id: 2, name: "교육관", selected: false },
  { id: 3, name: "대강당", selected: false },
  { id: 4, name: "후윳길", selected: false },
  { id: 5, name: "포스코관", selected: false },
  { id: 6, name: "학문관", selected: false },
  { id: 7, name: "생활관", selected: false },
  { id: 8, name: "신세계관", selected: false },
];
