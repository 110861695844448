import frontDoor from "../images/category/frontDoor.svg";
import edu from "../images/category/edu.svg";
import auditorium from "../images/category/auditorium.svg";
import hooyou from "../images/category/hooyou.svg";
import posco from "../images/category/posco.svg";
import student from "../images/category/student.svg";
import life from "../images/category/life.svg";
import sin from "../images/category/sin.svg";

export const boothMaps = [
  null,
  frontDoor,
  edu,
  auditorium,
  hooyou,
  posco,
  student,
  life,
  sin,
];
